<template>
  <v-sheet id="home">
    <v-container class="py-12">
      <v-row id="banner_row">
        <div class="doctors_banner">
        </div>
      </v-row>
      <v-row id="content_row">
        <v-col cols="12" md="12" lg="12">
          <h1 class="text-h6 text-sm-h5 secondary--text mt-4">
            Welcome to the website for the Independent Emergency Room Physician Trust, referred to as IER Physician
            Trust or Trust.
            <br/><br/>
            On October 12, 2020, Mallinckrodt plc (“Mallinckrodt”) a manufacturer and supplier of opioids
            , voluntarily initiated Chapter 11 proceedings in the U.S. Bankruptcy Court. On March 2,
            2022, the Bankruptcy Court entered an order approving the <a href="https://ierp-documents.s3.amazonaws.com/Order+Confirming+Joint+Amended+Plan+of+Reorganization.pdf">Joint Plan of Reorganization and the Disclosure
            Statement</a> which included procedures for establishing a number of Trusts to
            distribute funds to eligible claimants for use <b><i>in abatement</i></b> of opioid misuse and abuse.
            <br/><br/>
            One of the Trusts created by the Bankruptcy Court related to the Chapter 11 reorganization, is the IER
            Physicians Trust. If you are an Independent Emergency Room Physician (IER Physician or IERP), you may be
            eligible for funds from the IER Physicians Trust. An Independent Emergency Room Physician is defined as an
            Emergency Room Physician that was a non-hospital employee that worked in an Emergency Room as defined by
            EMTALA during the years 2001-2019. We believe, and the US Trustee agrees, that IER Physicians were damaged
            by the opioid epidemic and have a vital role in solving this problem. You are the front line. IER Physicians
            are often involved in the diagnose, management, identification and treatment of  opioid misuse and abuse
            cases. You have the ability to provide treatment, and referral and steer and guide patients to treatment and
            resources that can help.
            <br/><br/>
            The Trust is designed to provide financial resources to IER Physicians who can demonstrate they were
            financially damaged in treating Opioid abuse and misuse cases. It is important for you to understand,
            the funds from this Trust <b>can only be used</b> for abatement purposes. To receive a Distribution of funds,
            you will have to provide information on your plan for abatement, proof of use and accountability. The
            approved abatement purposes are identified in the Trust Document and listed
            <a href="https://ierp-documents.s3.amazonaws.com/Authorized+Abatement+Purposes.pdf">here</a>. In addition to
            the Trust, a process was also created and governs disbursement of funds. This document is called the
            <a href="https://ierp-documents.s3.amazonaws.com/IER+Physicians+Abatement+Distribution+Form.pdf">IER Physicians Trust Distribution Plan</a> (herein after “TDP”).
            <br/><br/>
            The Trust and TDP identify a process which is comprised of 2 - steps to determine eligibility
            for Trust funds. Step 1 determines initial eligibility to be a <i>"Holder of an IER Physicians Channeled Claim"</i>
            and to qualify as an "Authorized Recipient" of Trust funds. Step 2, the second step in the process,
            requires anyone that meets the requirements of Step 1 and has a Channeled Claim and qualifies as an
            Authorized Recipient to provide information that enables the Claims Administrator to calculate damages
            and determine the amount of any distribution that will be made from available Trust funds and which will
            then be distributed to the <i>"Authorized Recipient"</i>. If you have been notified your Step 1
            application has been approved and you are eligible to complete Step 2, you will receive an
            email explaining how to log in to complete the Step 2 Abatement Distribution Form. After receipt
            of that email, you can access Step 2 log in <a href="https://dev.ierptrust.net/#/protected/AbatementDistribution">here</a>.
            <br/><br/>
            If you believe you incurred damages, and will use any disbursement for one of the approved abatement
            processes, you can begin the process to see if you qualify for a disbursement.
            <br/><br/>
            To begin the process, email the Claims Administrator at <a :href="`mailto:${config.product.email_claims}?subject=Contact IERP Claims`" class="text-decoration-none" target="_blank">{{ config.product.email_claims }}</a>.
            <br>
            In the body of the email please make sure you indicate clearly that you are requesting the Step 1 form,
            <b>NOTICE OF POSSIBLE ELIGIBILITY FOR ABATEMENT DISTRIBUTION AWARD- STEP ONE <a href="https://ierp-documents.s3.amazonaws.com/Notice+of+Possible+Eligibility+for+Abatement+Distribution+Award+-+STEP+ONE.pdf">here</a>.
            Pursuant to the TDP, you must provide a physical originally signed copy of the Step 1 completed form
            (along with supporting documentation) to the Claims Administrator by US mail. You can also provide a
            duplicate via email if you choose.</b>
            <br><br>
            Submit a signed original Step 1 form, with supporting documentation to:<br>
            Claims Administrator,<br>
            IERP Trust, 1121 Park West Blvd.<br>
            Suite B #186<br>
            Mt. Pleasant, SC 29466-7122
            <br><br>
            <b style="color: red">The entire process, including Step 1 and Step 2 must be completed
            by the Applicant/Holder of a Channeled Claim, no later than July 31, 2023 to be considered for an Abatement
            Distribution.</b> Please take the deadlines in the Step 1 and Step 2 forms for Claims Administrator approval
            and processing into consideration when completing both Steps.
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import config from '@/configs'

export default {
  data: () => ({
    config
  })
}
</script>

<style>
.doctors_banner {
  background-image: url('../../../../assets/images/doctors_banner.jpg');
  width: 1200px;
  height: 400px;
  background-position: center;
  background-size: cover;
  text-align: center;
}
</style>